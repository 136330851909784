import React from "react";
import { Container } from "reactstrap";

import Header from "components/Headers/Header.js";
import "react-phone-input-2/lib/style.css";

import NFCList from "./nfc-list";

const CardManagement = () => {
  return (
    <div>
      <Header />
      <Container className="mt--5" fluid>
        <NFCList />
      </Container>
    </div>
  );
};

export default CardManagement;
