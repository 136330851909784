import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupText,
  InputGroupAddon,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";
import Header from "../../components/Headers/Header";
import { useToasts } from "react-toast-notifications";
import { postMethod, getUserId } from "services/httpServices";
import { useHistory } from "react-router";
import swal from "sweetalert";
import FullPageLoader from "components/FullPageLoader/fullpageloader";
import { getUserEmail } from "services/httpServices";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { getAmt } from "services/util";
import { getLoginType } from "services/httpServices";
import { getSuperAgentId } from "services/httpServices";
import { useLocation } from "react-router-dom/cjs/react-router-dom";
import LapoLogo from "../../assets/img/brand/lapologo.PNG";
import AfrMoney from "../../assets/img/brand/afrmoney.png";

const initialValue = {
  toMobWithCC: "",
  toMobWithoutCC: "",
  toMobCC: "",
  amount: "",
  pin: "",
  otp: "",
  walletId: "",
  accountID: "",
};
const STR1 = `Please enter the mobile number whom you want to transfer`;
const STR2 = `Enter Wallet ID`;

const mmos = [
  {
    label: "Afri Money",
    logo: AfrMoney,
  },
];

const CashInSub = (props) => {
  const [values, setValues] = useState(initialValue);
  const [subsInfo, setSubsInfo] = React.useState({});
  const [SubscriberDetails, setSubscriberDetails] = React.useState({});
  const [toUserInfo, setToUserInfo] = React.useState({});
  const [errors, setErrors] = React.useState({});
  const [loading, setLoading] = React.useState(false);
  const [spinner, setSpinner] = React.useState(false);
  const [use1FA, set1FA] = React.useState(false);
  const [use2FA, set2FA] = React.useState(false);
  const [showPswd, setShowPswd] = React.useState(false);
  const [form, setForm] = React.useState(1);
  const [resendLoader, setResendLoader] = React.useState(false);
  const [paymentType, setPaymentType] = React.useState("lapo"); // Initial option: 'lapo'
  const [mmoType, setMmoType] = React.useState("Afri Money"); // Initial option: 'lapo'
  const [walletInfo, setWalletInfo] = useState(null);
  const [isWalletVerified, setIsWalletVerified] = useState(-1);
  const [lapoType, setLapoType] = useState("wallet");

  const { addToast } = useToasts();
  const history = useHistory();
  const routeName = useLocation();
  let loginType = getLoginType();
  // console.log(routeName.pathname);

  /**======================== React Hooks ==========================*/

  useEffect(() => {
    fetchSubs();
  }, []);

  /**======================== File Functions ==========================*/

  const notifyFailed = (text) => {
    if (!text) {
      text = "Error in saving";
    }
    addToast(text, {
      appearance: "error",
      autoDismiss: true,
    });
  };

  const notifySuccess = (text) => {
    if (!text) {
      text = "Save successfully";
    }
    addToast(text, {
      appearance: "success",
      autoDismiss: true,
    });
  };

  const openModal = (msg) => {
    swal({
      // title: msg,
      icon: "success",
      text: msg,
      allowOutsideClick: false,
      allowEscapeKey: false,
      closeOnClickOutside: false,
      buttons: {
        confirm: {
          text: "Ok",
          visible: true,
          closeModal: true,
          className: "sweet-btn",
        },
      },
    }).then((confirm) => {
      setSpinner(false);
      if (confirm) {
        setForm(3);
      } else {
        setForm(3);
      }
    });
  };

  const phoneChange = (value, data, event, formattedValue) => {
    if (data.dialCode && data.dialCode.length) {
      setValues({
        ...values,
        toMobWithCC: value,
        toMobCC: data.dialCode,
        toMobWithoutCC: value.substring(data.dialCode.length),
      });
    }
    return;
  };

  const goBack1 = () => {
    setValues(initialValue);
    setToUserInfo({});
    setForm(1);
  };

  const goBack2 = () => {
    setValues({ ...values, otp: "", pin: "" });
    setForm(2);
  };

  const resendOtp = (e) => {
    e.preventDefault();
    setValues({ ...values, otp: "" });
    setResendLoader(true);
    genOtp();
  };

  const checkForm = () => {
    if (form == 2 || form == 3) {
      return true;
    } else {
      return false;
    }
  };

  /**======================== Form Validation function ==========================*/

  const handleSubmit1 = (e) => {
    e.preventDefault();
    if (mmoType === "Lapo") {
     const validationErrors = validate1(values);
      setErrors(validationErrors);
      const isNoError = Object.values(validationErrors).every((x) => x === "");
      if (isNoError) {
        if (lapoType === "wallet") {
          checkToMob();
        } else {
          transferToBank();
        }
      }
    }

    if (mmoType === "Afri Money") {
      if (isWalletVerified === 1) {
        const validationErrors = validate2(values);
        setErrors(validationErrors);
        const isNoError = Object.values(validationErrors).every(
          (x) => x === ""
        );
        if (isNoError) {
            onCashIn();
        }
        return;
      }
      const validationErrors = validate4(values);
      setErrors(validationErrors);
      const isNoError = Object.values(validationErrors).every((x) => x === "");
      if (isNoError) {
        verifyWallet();
      }
    }
  };

  function htmlToJSON(html) {
    // Create a new DOM parser
    const parser = new DOMParser();
    // Parse the HTML string
    const doc = parser.parseFromString(html, "text/html");
    // Get the content inside the body tag
    const bodyContent = doc.body.textContent.trim();

    // Split the string into key-value pairs
    const keyValuePairs = bodyContent.split(",");

    // Initialize an empty object
    const result = {};

    // Iterate over the key-value pairs and add them to the result object
    keyValuePairs.forEach((pair) => {
      const [key, value] = pair.split("=");
      // Remove the surrounding quotes from the value and add the key-value pair to the result object
      result[key.trim()] = value.replace(/"/g, "").trim();
    });

    return result;
  }

  const onCashIn = () => {
    var errorMsg = "";

    const { walletId, pin, amount } = values;
    console.log({walletInfo})
    var data = {
      customerName: `${walletInfo.fname} ${walletInfo.lname}`,
      amount: Number(amount),
      msisdn: walletId,
      pin,
    };
    let url = "/AfriMoney/CashIn";

    setSpinner(true);
    // let url = '/api/pos/PaymentPinVerification';
    postMethod(url, JSON.stringify(data), "post")
      .then((res) => {
        console.log({res})
        if (res.data) {
          if (res.data.responseCode === "200") {
            let msg = res.data.responseMessage || "Amount Transferred";
            notifySuccess(msg);
            setWalletInfo(null);
            history.push("/index");
            setIsWalletVerified(-1);
            setSpinner(false);
          } else {
            setIsWalletVerified(0);
            errorMsg =
              res.data?.responseMessage ||
              "Something went wrong!, try again later";
            notifyFailed(errorMsg);
            setSpinner(false);
          }
        } else {
          setIsWalletVerified(0);
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
          setSpinner(false);
        }
      })
      .catch((err) => {
        errorMsg = "Internal Server Error";
        notifyFailed(errorMsg);
        setSpinner(false);
        setIsWalletVerified(0);
      });
  };

  const verifyWallet = () => {
    var errorMsg = "";

    const { walletId } = values;

    let url = "/AfriMoney/VerifyCustomer/" + walletId;

    setSpinner(true);
    // let url = '/api/pos/PaymentPinVerification';
    postMethod(url, {}, "post")
      .then((res) => {
        console.log({res})
        if (res.data) {
          if (res.data.responseCode === "200") {
            setSpinner(false);
            let result = res.data.result?.command;
            if (Object.values(result).includes(null)) {
              notifyFailed("Wallet id not found");
              return;
            }
            setIsWalletVerified(1);
            setWalletInfo(result);
          } else {
            setIsWalletVerified(0);
            errorMsg =
              res.data?.responseMessage ||
              "Something went wrong!, try again later";
            notifyFailed(errorMsg);
            setSpinner(false);
          }
        } else {
          setIsWalletVerified(0);
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
          setSpinner(false);
        }
      })
      .catch((err) => {
        errorMsg =
          err?.response?.data?.responseMessage || "Internal Server Error";
        notifyFailed(errorMsg);
        setSpinner(false);
        setIsWalletVerified(0);
      });
  };

  const validate1 = (inputs) => {
    var errors = {};
    if (lapoType === "wallet") {
      if (!inputs.toMobWithCC) {
        errors.toMobWithCC = "Phone number is required";
      } else if (!inputs.toMobWithoutCC) {
        errors.toMobWithCC = "Phone number is required";
      }
    } else {
      if (!inputs.accountID) {
        errors.accountID = "Account Number is required";
      }
      if (!inputs.pin) {
        errors.pin = "Pin is required";
      }
      if (!inputs.amount) {
        errors.amount = "Amount is required";
      }
    }
    return errors;
  };

  const validate4 = (inputs) => {
    var errors = {};
    if (!inputs.walletId) {
      errors.walletId = "Wallet Id is required";
    }
    return errors;
  };

  const handleSubmit2 = (e) => {
    e.preventDefault();
    const validationErrors = validate2(values);
    setErrors(validationErrors);
    const isNoError = Object.values(validationErrors).every((x) => x === "");
    if (isNoError) {
      verifyPinV2();
    }
  };

  const validate2 = (inputs) => {
    var errors = {};
    if (!inputs.amount) {
      errors.amount = "Amount is required";
    }
    if (!inputs.pin) {
      errors.pin = "Pin is required";
    }
    return errors;
  };

  const handleSubmit3 = (e) => {
    e.preventDefault();
    const validationErrors = validate3(values);
    setErrors(validationErrors);
    const isNoError = Object.values(validationErrors).every((x) => x === "");
    if (isNoError) {
      transferMoney();
    }
  };

  const validate3 = (inputs) => {
    var errors = {};
    if (!inputs.amount) {
      errors.amount = "Amount is required";
    }
    if (!inputs.pin) {
      errors.pin = "Pin is required";
    }
    if (use2FA && !inputs.otp) {
      errors.otp = "OTP is required";
    }
    if (!toUserInfo || !toUserInfo.userId) {
      errors.toUserId = "To userid is required";
    }
    return errors;
  };

  const handleInputChange = (ev) => {
    var { name, value } = ev.target;
    value = value.trim();
    if (name === "amount") {
      if (value == "") {
        setValues({ ...values, [name]: value });
      } else {
        if (checkNumberOnly(value)) {
          setValues({ ...values, [name]: value });
        } else {
          setValues({ ...values, [name]: "" });
        }
      }
    } else if (name === "password") {
      if (pinValidation(value)) {
        setValues({ ...values, [name]: value });
      }
    } else if (name === "otp") {
      if (checkOTP(value)) {
        setValues({ ...values, [name]: value });
      }
    } else {
      setValues({ ...values, [name]: value });
    }
  };

  const checkOTP = (value) => {
    let digiRegex = /^[0-9]*$/;
    if (digiRegex.test(value)) {
      if (value.length <= 6) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const pinValidation = (value) => {
    let digiRegex = /^[0-9]*$/;
    if (value.length <= 4) {
      if (digiRegex.test(value)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const checkNumberOnly = (value) => {
    // let digiRegex = /^[0-9]*$/;
    let digiRegex = /^[+-]?([1-9]+\.?[0-9]*|\.[0-9]+)$/;
    if (digiRegex.test(value)) {
      return true;
    } else {
      return false;
    }
  };

  /**======================== API Calls ==========================*/

  const fetchSubs = () => {
    let errorMsg = "";
    let id = getUserId();

    let url = "";
    if (loginType === "Super Agent") {
      url = "/api/agent/GetSuperAgent/" + id;
    } else if (loginType === "Master Agent") {
      url = "/api/Agent/GetMasterAgentById/" + id;
    } else if (loginType === "Agent") {
      // setForm(2);
      // checkToMob();
      return;
    } else {
      url = "/api/Subscriber/GetByUserId/" + id;
    }
    setLoading(true);
    postMethod(url, null, "post")
      .then((res) => {
        setLoading(false);
        if (res && res.data) {
          if (res.data.responseCode === "200") {
            // Success
            setSubsInfo(res.data.result);
            setValues({
              ...values,
              accountID: res?.data?.result?.bankAccountNumber ?? "",
            });
          } else if (res.data.responseCode === "400") {
            // Error
            errorMsg =
              res.data.responseMessage || "Error in fetching subscriber info";
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = "Unknown Error in fetching subscriber info";
            notifyFailed(errorMsg);
          }
        } else {
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
        }
      })
      .catch((err) => {
        setLoading(false);
        notifyFailed("Internal Server Error");
      });
  };

  const checkToMob = () => {
    let errorMsg = "";
    setSpinner(true);

    let url = "";
    if (loginType === "Super Agent") {
      url = "/api/Transactions/validateSubAgent/";
    } else if (loginType === "Master Agent") {
      url = "/api/Transactions/validateSuperAgent/";
    } else if (loginType === "Agent") {
      url = "/api/Agent/GetSuperAgent/" + getSuperAgentId();
    } else {
      if (
        routeName.pathname.includes("cash-out") &&
        loginType === "Subscriber"
      ) {
        // url = "/api/Transactions/validateSubAgent-Subscriber/";
        url = "/api/Transactions/validateSubscriber/";

      } else {
        url = "/api/Transactions/validateSubscriber/";
      }
    }
    postMethod(url + values.toMobWithCC, {}, "post")
      .then((res) => {
        setSpinner(false);
        if (res && res.data) {
          if (res.data.responseCode === "200") {
            // Success
            setToUserInfo({
              ...res.data.result,
              userId:
                res.data.result?.userId || res.data?.result?.userProfileId,
            });
            setForm(2);
          } else if (res.data.responseCode === "400") {
            // Error
            errorMsg =
              res.data.responseMessage || "Error in fetching user info";
            notifyFailed(errorMsg);
          } else {
            // Unknown Error
            errorMsg = "Unknown Error in fetching user info";
            notifyFailed(errorMsg);
          }
        } else {
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
        }
      })
      .catch((err) => {
        setSpinner(false);
        notifyFailed("Internal Server Error");
      });
  };

  const verifyPinV2 = () => {
    var errorMsg = "";
    // var data = {
    //   "PaymentAmount": Number(values.amount),
    //   "Password": values.pin,
    //   "Email": getUserEmail().trim(),
    //   "PaymentType": "BalanceTransfer"
    // };

    var data = {
      PaymentAmount: Number(values.amount),
      Password: values.pin,
      Email: getUserEmail().trim(),
      PaymentType: "BalanceTransfer",
    };
    let url = "";

    if (loginType === "Super Agent") {
      url = "/api/agent/GetSubAgentDetails";
      data.Email = subsInfo.mobNumber;
    } else if (loginType === "Master Agent") {
      url = "/api/Agent/GetSuperAgentDetails";
      data.Email = subsInfo.mobNumber;
    } else if (loginType === "Agent") {
      url = "/api/Agent/GetSuperAgent/" + getSuperAgentId();
    } else {
      if (
        routeName.pathname.includes("cash-out") &&
        loginType === "Subscriber"
      ) {
        url = "/api/Subscriber/GetSubscriberDetailsForCashout/";
      } else {
        url = "/api/Subscriber/GetSubscriberDetails/";
      }
    }
    setSpinner(true);
    // let url = '/api/pos/PaymentPinVerification';
    postMethod(url, JSON.stringify(data), "post")
      .then((res) => {
        if (res.data) {
          if (res.data.responseCode === "200") {
            let result = res.data.result;
            setSubscriberDetails(result);
            if (result.authFactorType == "1FA") {
              set1FA(true);
              set2FA(false);
            } else if (result.authFactorType == "2FA") {
              set1FA(false);
              set2FA(true);
            } else {
              set1FA(false);
              set2FA(false);
            }
            verifyPinHandle(result);
          } else if (res.data.responseCode === "400") {
            errorMsg = res.data.responseMessage || "Error in validating pin";
            notifyFailed(errorMsg);
            setSpinner(false);
          } else {
            errorMsg = "Unknown error in validating pin";
            notifyFailed(errorMsg);
            setSpinner(false);
          }
        } else {
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
          setSpinner(false);
        }
      })
      .catch((err) => {
        errorMsg = "Internal Server Error";
        notifyFailed(errorMsg);
        setSpinner(false);
      });
  };

  const transferToBank = () => {
    var errorMsg = "";
    // var data = {
    //   "PaymentAmount": Number(values.amount),
    //   "Password": values.pin,
    //   "Email": getUserEmail().trim(),
    //   "PaymentType": "BalanceTransfer"
    // };

    var data = {
      amount: values.amount,
      pin: values.pin,
      accountID: values.accountID,
    };
    let url = "/api/Transactions/TransferToDepositAccount";

    setSpinner(true);

    postMethod(url, JSON.stringify(data), "post")
      .then((res) => {
        setSpinner(false);
        if (res.data.responseCode === "200") {
          let msg = res.data.responseMessage || "Amount Transferred";
          notifySuccess(msg);
          history.push("/index");
        } else if (res.data.responseCode === "400") {
          errorMsg = res.data.responseMessage || "Error in transferring amount";
          notifyFailed(errorMsg);
        } else {
          errorMsg = "Unknown error in transferring amount";
          notifyFailed(errorMsg);
        }
      })
      .catch((err) => {
        errorMsg = "Internal Server Error";
        notifyFailed(errorMsg);
        setSpinner(false);
      });
  };

  const verifyPinHandle = (result) => {
    if (result.authFactorType == "2FA") {
      genOtp();
    } else {
      setSpinner(false);
      setForm(3);
    }
  };

  const genOtp = () => {
    var errorMsg = "";
    var data = JSON.stringify({
      UserId: getUserId(),
      OtpType: "BalanceTransfer",
    });
    let url = "/api/Transactions/Sendotp";
    postMethod(url, data, "post")
      .then((res) => {
        setSpinner(false);
        setResendLoader(false);
        if (res.data) {
          if (res.data.responseCode === "200") {
            let msg = res.data.responseMessage || "Otp sent";
            // notifySuccess(msg);
            openModal(msg);
          } else if (res.data.responseCode === "400") {
            errorMsg = res.data.responseMessage || "Error in generating otp";
            notifyFailed(errorMsg);
            setSpinner(false);
          } else {
            errorMsg = "Unknown error in generating otp";
            notifyFailed(errorMsg);
            setSpinner(false);
          }
        } else {
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
          setSpinner(false);
        }
      })
      .catch((err) => {
        setSpinner(false);
        setResendLoader(false);
        errorMsg = "Internal Server Error";
        notifyFailed(errorMsg);
      });
  };

  const transferMoney = () => {
    var errorMsg = "";
    var data = {
      ToUserId: toUserInfo.userId,
      Amount: Number(values.amount),
      PIN: values.pin,
    };
    if (use2FA) {
      data["Otp"] = values.otp;
    }
    setSpinner(true);

    let url = "";
    if (loginType === "Super Agent") {
      url = "/api/Transactions/walletBalanceTransferToSubAgent";
    } else if (loginType === "Master Agent") {
      url = "/api/Transactions/walletBalanceTransferToSuperAgent";
    } else if (loginType === "Agent") {
      url = "/api/Transactions/walletBalanceTransferToSuperAgentReverse";
      data.ToUserId = getSuperAgentId();
    } else {
      if (
        routeName.pathname.includes("cash-out") &&
        loginType === "Subscriber"
      ) {
        // url = "/api/Transactions/walletBalanceTransferToSubAgentFromSubscriber";
        url = "/api/Transactions/walletBalanceTransfer";
      } else {
        url = "/api/Transactions/walletBalanceTransfer";
      }
    }
    postMethod(url, data, "post")
      .then((res) => {
        setSpinner(false);
        if (res.data) {
          if (res.data.responseCode === "200") {
            let msg = res.data.responseMessage || "Amount Transferred";
            notifySuccess(msg);
            history.push("/index");
          } else if (res.data.responseCode === "400") {
            errorMsg =
              res.data.responseMessage || "Error in transferring amount";
            notifyFailed(errorMsg);
          } else {
            errorMsg = "Unknown error in transferring amount";
            notifyFailed(errorMsg);
          }
        } else {
          errorMsg = "Unknown Error";
          notifyFailed(errorMsg);
        }
      })
      .catch((err) => {
        setSpinner(false);
        errorMsg = "Internal Server Error";
        notifyFailed(errorMsg);
      });
  };

  const resetState = () => {
    setWalletInfo(null);
    setIsWalletVerified(-1);
    setSpinner(false);
  };

  return (
    <>
      <Header />
      <Container className="mt--5 pb-5">
        <Row className="justify-content-center">
          <Col lg="6" md="6">
            <Card className="bg-secondary shadow border-0">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col lg="12">
                    <h3 className="mb-0 text-center">Cash In</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody
                className="px-lg-5 py-lg-4"
                style={{ minHeight: "300px" }}
              >
                {loading && <FullPageLoader />}

                <Row
                  className="mt-2"
                  style={{
                    display: "flex",
                    gap: "12px",
                    flexDirection: "column",
                  }}
                >
                  <span>Select Cash In</span>
                  <div style={{ display: "flex", gap: "12px" }}>
                    {mmos.map((item) => (
                      <FormGroup
                        className="d-flex align-items-center justify-content-center flex-column"
                        style={{ gap: "12px", width: "100px" }}
                      >
                        <button
                          htmlFor={item.label}
                          className="d-flex align-items-center  justify-content-center w-100 bg-white-original p-3 shadow rounded"
                          style={{
                            gap: "12px",
                            border:
                              item.label === "Lapo" && mmoType === "Lapo"
                                ? "1px solid orange"
                                : item.label === "Afri Money" &&
                                  mmoType === "Afri Money"
                                ? "1px solid #a11776"
                                : "1px solid #ccc",
                          }}
                          onClick={(e) => {
                            resetState();
                            setMmoType(item.label);
                          }}
                          type="button"
                        >
                          <div className="d-flex align-items-center flex-column">
                            <img
                              src={item.logo}
                              alt={item.label}
                              height={60}
                              width={60}
                            />
                            <span
                              style={{
                                fontSize: "12px",
                              }}
                            >
                              {item.label}
                            </span>
                          </div>
                        </button>
                      </FormGroup>
                    ))}
                  </div>
                </Row>

                {!loading && (
                  <>
                    {form == 1 && (
                      <Form role="form" onSubmit={handleSubmit1}>
                        {mmoType === "Lapo" && (
                          <>
                            <Row>
                              <Col>
                                <strong>
                                  <label>
                                    {paymentType === "lapo" ? STR1 : STR2}
                                  </label>
                                </strong>
                              </Col>
                            </Row>
                            <Row className="mt-4">
                              <Col>
                                <FormGroup>
                                  <PhoneInput
                                    className="form-control-alternative"
                                    country={"sl"}
                                    value={values.toPhone}
                                    enableSearch
                                    //   onlyCountries={MOBILE_ONLY_COUNTRY}
                                    countryCodeEditable={false}
                                    onChange={phoneChange}
                                  />

                                  {errors.toMobWithCC && (
                                    <div className="text-left text-danger">
                                      <small>* {errors.toMobWithCC}</small>
                                    </div>
                                  )}
                                </FormGroup>
                              </Col>
                            </Row>
                          </>
                        )}

                        {mmoType === "Afri Money" && (
                          <>
                           <Row>
                           <Col lg="12">
                              <strong>
                                <label>
                                  {paymentType === "Lapo" ? STR1 : STR2}
                                </label>
                              </strong>
                            </Col>
                           </Row>
                           <Row>
                           <Col lg="12">
                              <FormGroup>
                                <InputGroup className="input-group-alternative">
                                  <Input
                                    className="form-control-alternative"
                                    id="input-walletId"
                                    placeholder="Wallet ID"
                                    type="text"
                                    name="walletId"
                                    value={values.walletId}
                                    onChange={handleInputChange}
                                    disabled={spinner || isWalletVerified === 1}
                                  />
                                  {isWalletVerified === 1 && (
                                    <InputGroupAddon addonType="append">
                                      <InputGroupText>
                                        <i class="fas fa-check-circle text-green"></i>{" "}
                                      </InputGroupText>
                                    </InputGroupAddon>
                                  )}
                                  {isWalletVerified === 0 && (
                                    <InputGroupAddon addonType="append">
                                      <InputGroupText>
                                        <i class="fas fa-times-circle text-red"></i>{" "}
                                      </InputGroupText>
                                    </InputGroupAddon>
                                  )}
                                </InputGroup>

                                {errors.walletId && (
                                  <div className="text-left text-danger">
                                    <small>* {errors.walletId}</small>
                                  </div>
                                )}
                              </FormGroup>
                            </Col>
                           </Row>
                          </>
                        )}

                        {isWalletVerified === 1 && (
                          <>
                            <Row>
                              <Col lg="12">
                                <strong>Wallet Details</strong>
                                <div className="mt-2">
                                  <div>
                                    <small>
                                      First Name - {walletInfo?.fname}
                                    </small>
                                  </div>
                                  <div>
                                    <small>
                                      Last Name - {walletInfo?.lname}
                                    </small>
                                  </div>
                                
                                  <div>
                                    <small>
                                      Date of Birth -{" "}
                                      {walletInfo?.dob}
                                    </small>
                                  </div>
                                  <div>
                                    <small>Gender - {walletInfo?.gender}</small>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Row className="mt-2">
                              <Col lg="12">
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-amount"
                                  >
                                    Amount
                                  </label>
                                  <Input
                                    className="form-control-alternative"
                                    id="input-amount"
                                    placeholder="Enter Amount"
                                    type="text"
                                    name="amount"
                                    value={values.amount}
                                    onChange={handleInputChange}
                                    disabled={spinner}
                                  />
                                  {errors.amount && (
                                    <div className="text-left text-danger">
                                      <small>* {errors.amount}</small>
                                    </div>
                                  )}
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <FormGroup>
                                  <label
                                    className="form-control-label"
                                    htmlFor="input-pin"
                                  >
                                    PIN
                                  </label>

                                  <InputGroup className="input-group-alternative">
                                    <Input
                                      className="form-control-alternative"
                                      id="input-pswd"
                                      type={showPswd ? "text" : "password"}
                                      name="pin"
                                      value={values.pin}
                                      placeholder="Enter 4-digit PIN"
                                      onChange={handleInputChange}
                                      disabled={spinner}
                                    />
                                    {!showPswd && (
                                      <InputGroupAddon addonType="append">
                                        <InputGroupText>
                                          <i
                                            className="fa fa-eye"
                                            onClick={() => setShowPswd(true)}
                                          />
                                        </InputGroupText>
                                      </InputGroupAddon>
                                    )}

                                    {showPswd && (
                                      <InputGroupAddon addonType="append">
                                        <InputGroupText>
                                          <i
                                            className="fa fa-eye-slash"
                                            onClick={() => setShowPswd(false)}
                                          />
                                        </InputGroupText>
                                      </InputGroupAddon>
                                    )}
                                  </InputGroup>
                                  {errors.pin && (
                                    <div className="text-left text-danger">
                                      <small>* {errors.pin}</small>
                                    </div>
                                  )}
                                </FormGroup>
                              </Col>
                            </Row>
                          </>
                        )}
                        <Row className="mt-3">
                          <Col>
                            {mmoType === "Lapo" ? (
                              <div>
                                <Button
                                  className="btn float-right"
                                  color="primary"
                                  type="submit"
                                  disabled={spinner}
                                >
                                  Submit{" "}
                                  {spinner && (
                                    <i class="fa fa-spinner fa-spin"></i>
                                  )}
                                </Button>
                              </div>
                            ) : (
                              <div className="d-flex justify-content-end w-100 flex-items-middle">
                                {isWalletVerified === 1 && (
                                  <Button
                                    className="btn"
                                    color="secondary"
                                    type="reset"
                                    disabled={spinner}
                                    onClick={() => {
                                      setIsWalletVerified(-1);
                                      setWalletInfo(null);
                                    }}
                                  >
                                    Cancel
                                  </Button>
                                )}
                                {mmoType && (
                                  <Button
                                    className="btn "
                                    color="primary"
                                    type="submit"
                                    disabled={spinner}
                                  >
                                    {isWalletVerified === 1
                                      ? "Submit"
                                      : "Verify"}
                                    {spinner && (
                                      <>
                                        &nbsp;
                                        <i class="fa fa-spinner fa-spin"></i>
                                      </>
                                    )}
                                  </Button>
                                )}
                              </div>
                            )}
                          </Col>
                        </Row>
                      </Form>
                    )}

                    {checkForm() && (
                      <Row>
                        <Col lg="12">
                          <strong>User Details</strong>
                          <div className="mt-2">
                            <div>
                              <small>
                                Transfer to -{" "}
                                {toUserInfo.subscriberName ||
                                  toUserInfo?.fullName}
                              </small>
                            </div>
                            <div>
                              <small className="mt-2">
                                Mobile Number -{" "}
                                {`(${
                                  toUserInfo.mobileCodeId ||
                                  toUserInfo?.mobileCode
                                }) - ${
                                  loginType === "Agent"
                                    ? toUserInfo?.mobileNumber
                                    : toUserInfo?.mobNumber
                                }`}
                              </small>
                            </div>
                            <div>
                              <small className="mt-2">
                                Email - {toUserInfo.email}
                              </small>
                            </div>
                            {form == 3 && (
                              <div>
                                <small className="mt-2">
                                  Amount - &nbsp;
                                  <strong>Le</strong> &nbsp;
                                  {getAmt(values.amount)}
                                </small>
                              </div>
                            )}
                            <div>
                              {routeName.pathname.includes("cash-out") ? (
                                <small>
                                  Fees - <b>Le</b> {SubscriberDetails.fees}
                                  &nbsp;(Sub Agent Commission:{" "}
                                  <b>
                                    {SubscriberDetails.commissionWillCredit}
                                  </b>{" "}
                                  & Subscriber Commission:{" "}
                                  <b>{SubscriberDetails.actualCommission})</b>
                                </small>
                              ) : null}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    )}

                    {form == 2 && (
                      <Form role="form" onSubmit={handleSubmit2}>
                        <Row className="mt-2">
                          <Col lg="12">
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-amount"
                              >
                                Amount
                              </label>
                              <Input
                                className="form-control-alternative"
                                id="input-amount"
                                placeholder="Enter Amount"
                                type="text"
                                name="amount"
                                value={values.amount}
                                onChange={handleInputChange}
                                disabled={spinner}
                              />
                              {errors.amount && (
                                <div className="text-left text-danger">
                                  <small>* {errors.amount}</small>
                                </div>
                              )}
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Col>
                            <FormGroup>
                              <label
                                className="form-control-label"
                                htmlFor="input-pswd"
                              >
                                PIN
                              </label>

                              <InputGroup className="input-group-alternative">
                                <Input
                                  className="form-control-alternative"
                                  id="input-pswd"
                                  type={showPswd ? "text" : "password"}
                                  name="pin"
                                  value={values.pin}
                                  placeholder="Enter 4-digit PIN"
                                  onChange={handleInputChange}
                                  disabled={spinner}
                                />
                                {!showPswd && (
                                  <InputGroupAddon addonType="append">
                                    <InputGroupText>
                                      <i
                                        className="fa fa-eye"
                                        onClick={() => setShowPswd(true)}
                                      />
                                    </InputGroupText>
                                  </InputGroupAddon>
                                )}

                                {showPswd && (
                                  <InputGroupAddon addonType="append">
                                    <InputGroupText>
                                      <i
                                        className="fa fa-eye-slash"
                                        onClick={() => setShowPswd(false)}
                                      />
                                    </InputGroupText>
                                  </InputGroupAddon>
                                )}
                              </InputGroup>
                              {errors.pin && (
                                <div className="text-left text-danger">
                                  <small>* {errors.pin}</small>
                                </div>
                              )}
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row className="mt-3">
                          {loginType !== "Agent" && (
                            <Col lg="6">
                              <div>
                                <Button
                                  className="btn float-left"
                                  color="primary"
                                  disabled={spinner}
                                  onClick={goBack1}
                                >
                                  <i
                                    class="fas fa-farrow-alt-circle-left"
                                    style={{ fontSize: "18px" }}
                                  ></i>
                                  &nbsp; Back
                                </Button>
                              </div>
                            </Col>
                          )}

                          <Col>
                            <div>
                              <Button
                                className="btn float-right"
                                color="primary"
                                type="submit"
                                disabled={spinner}
                              >
                                Submit{" "}
                                {spinner && (
                                  <i class="fa fa-spinner fa-spin"></i>
                                )}
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    )}

                    {form == 3 && (
                      <Form role="form" onSubmit={handleSubmit3}>
                        {use2FA && (
                          <Row className="mt-3">
                            <Col>
                              <FormGroup>
                                <label
                                  className="form-control-label"
                                  htmlFor="input-otp"
                                >
                                  OTP
                                </label>

                                <div className="float-right text-small">
                                  <small>
                                    <a href="#pablo" onClick={resendOtp}>
                                      Resend
                                    </a>{" "}
                                    &nbsp; &nbsp;
                                    {resendLoader && (
                                      <div
                                        class="spinner-border spinner-border-sm"
                                        role="status"
                                      >
                                        <span class="sr-only"></span>
                                      </div>
                                    )}
                                  </small>
                                </div>

                                <Input
                                  className="form-control-alternative"
                                  id="input-otp"
                                  placeholder="Enter OTP"
                                  type="text"
                                  name="otp"
                                  value={values.otp}
                                  onChange={handleInputChange}
                                />
                                <div className="text-left text-muted">
                                  <small>
                                    <i style={{ fontSize: "12px" }}>
                                      * we have sent you an OTP on your
                                      registered mobile number / email
                                    </i>
                                  </small>
                                </div>
                                {errors.otp && (
                                  <div className="text-left text-danger">
                                    <small>* {errors.otp}</small>
                                  </div>
                                )}
                              </FormGroup>
                            </Col>
                          </Row>
                        )}

                        <Row className="mt-4">
                          <Col lg="6">
                            <div>
                              <Button
                                className="btn float-left"
                                color="primary"
                                onClick={goBack2}
                                disabled={spinner}
                              >
                                <i
                                  class="fas fa-arrow-alt-circle-left"
                                  style={{ fontSize: "18px" }}
                                ></i>{" "}
                                &nbsp; Back
                              </Button>
                            </div>
                          </Col>
                          <Col lg="6">
                            <div>
                              <Button
                                className="btn float-right"
                                color="primary"
                                type="submit"
                                disabled={spinner}
                              >
                                Submit{" "}
                                {spinner && (
                                  <i class="fa fa-spinner fa-spin"></i>
                                )}
                              </Button>
                            </div>
                          </Col>
                        </Row>
                      </Form>
                    )}
                  </>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default CashInSub;
